import * as React from "react";
import { makeUseContext } from "../utils.js";
const TimeoutsContext = React.createContext(null);
export const useTimeouts = makeUseContext("useTimeouts", "TimeoutsContext", TimeoutsContext);
export const TimeoutsProvider = ({ children }) => {
    const timeouts = React.useRef([]);
    const removeTimeout = (id) => {
        timeouts.current.splice(0, timeouts.current.length, ...timeouts.current.filter((tid) => tid !== id));
    };
    const setTimeout = (func, time) => {
        const id = window.setTimeout(() => {
            removeTimeout(id);
            func();
        }, time);
        timeouts.current.push(id);
        return id;
    };
    const clearTimeout = (id) => {
        if (typeof id !== "undefined") {
            removeTimeout(id);
            window.clearTimeout(id);
        }
    };
    const clearTimeouts = () => {
        timeouts.current.forEach((tid) => window.clearTimeout(tid));
        timeouts.current.splice(0, timeouts.current.length);
    };
    React.useEffect(() => () => clearTimeouts(), []);
    const context = React.useRef({
        setTimeout,
        clearTimeout,
        clearTimeouts,
    });
    return React.createElement(TimeoutsContext.Provider, { value: context.current }, children);
};
