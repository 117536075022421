import Logo from "../../assets/svg/logo";
import { DesignDetial } from "../../interfaces/designDetail";
import { DesignPhotoRow } from "../../interfaces/DesignPhotoRow";
import { DesignRow } from "../designRow/designRow";
import "./designModal.scss";

interface IProps {
  designDetial: DesignDetial;
  closeModal: any;
}

export function DesignDetailModal(props: IProps) {
  return (
    <section className="design-modal">
      <div className="design-modal-header">
        <div className="design-modal-header-toolbar">
          <div className="design-modal-header-toolbar-button clickable"  onClick={() => {
              props.closeModal();
            }}>
            <svg
              width="37.14258px"
              height="37.14258px"
              viewBox="0 0 37.14258 37.14258"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="􀰎">
                <path
                  d="M18.5625 37.1426C21.1172 37.1426 23.5137 36.6592 25.752 35.6924C27.9902 34.7256 29.9619 33.3867 31.667 31.6758C33.3721 29.9648 34.7109 27.9902 35.6836 25.752C36.6563 23.5137 37.1426 21.1172 37.1426 18.5625C37.1426 16.0195 36.6563 13.6289 35.6836 11.3906C34.7109 9.15234 33.3721 7.17773 31.667 5.4668C29.9619 3.75586 27.9873 2.41699 25.7432 1.4502C23.499 0.483398 21.1055 0 18.5625 0C16.0195 0 13.626 0.483398 11.3818 1.4502C9.1377 2.41699 7.16309 3.75586 5.45801 5.4668C3.75293 7.17773 2.41699 9.15234 1.4502 11.3906C0.483398 13.6289 0 16.0195 0 18.5625C0 21.1172 0.483398 23.5137 1.4502 25.752C2.41699 27.9902 3.75586 29.9648 5.4668 31.6758C7.17773 33.3867 9.15234 34.7256 11.3906 35.6924C13.6289 36.6592 16.0195 37.1426 18.5625 37.1426ZM8.92969 18.5449C8.92969 17.8887 9.1875 17.3203 9.70313 16.8398L16.0313 10.793C16.418 10.418 16.875 10.2305 17.4023 10.2305C17.918 10.2305 18.3428 10.4004 18.6768 10.7402C19.0107 11.0801 19.1777 11.5195 19.1777 12.0586C19.1777 12.5859 18.9727 13.0371 18.5625 13.4121L16.5059 15.293L14.6426 16.6641L18.2637 16.4355L26.2617 16.4355C26.8477 16.4355 27.3252 16.6377 27.6943 17.042C28.0635 17.4463 28.248 17.9473 28.248 18.5449C28.248 19.1426 28.0635 19.6436 27.6943 20.0479C27.3252 20.4521 26.8477 20.6543 26.2617 20.6543L18.2637 20.6543L14.625 20.4434L16.4531 21.7969L18.5625 23.6953C18.9727 24.0352 19.1777 24.4863 19.1777 25.0488C19.1777 25.5879 19.0107 26.0303 18.6768 26.376C18.3428 26.7217 17.918 26.8945 17.4023 26.8945C16.875 26.8945 16.418 26.7012 16.0313 26.3145L9.70313 20.2676C9.1875 19.7871 8.92969 19.2129 8.92969 18.5449Z"
                  id="Shape"
                  fill="#8A8A8F"
                  stroke="none"
                />
              </g>
            </svg>
            <div className="design-modal-header-toolbar-button-text">
            Design
            </div>
          </div>
          <Logo white={"light"} />
          <div className="design-modal-header-toolbar-button hidden">
            <svg
              width="37.14258px"
              height="37.14258px"
              viewBox="0 0 37.14258 37.14258"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="􀰎">
                <path
                  d="M18.5625 37.1426C21.1172 37.1426 23.5137 36.6592 25.752 35.6924C27.9902 34.7256 29.9619 33.3867 31.667 31.6758C33.3721 29.9648 34.7109 27.9902 35.6836 25.752C36.6563 23.5137 37.1426 21.1172 37.1426 18.5625C37.1426 16.0195 36.6563 13.6289 35.6836 11.3906C34.7109 9.15234 33.3721 7.17773 31.667 5.4668C29.9619 3.75586 27.9873 2.41699 25.7432 1.4502C23.499 0.483398 21.1055 0 18.5625 0C16.0195 0 13.626 0.483398 11.3818 1.4502C9.1377 2.41699 7.16309 3.75586 5.45801 5.4668C3.75293 7.17773 2.41699 9.15234 1.4502 11.3906C0.483398 13.6289 0 16.0195 0 18.5625C0 21.1172 0.483398 23.5137 1.4502 25.752C2.41699 27.9902 3.75586 29.9648 5.4668 31.6758C7.17773 33.3867 9.15234 34.7256 11.3906 35.6924C13.6289 36.6592 16.0195 37.1426 18.5625 37.1426ZM8.92969 18.5449C8.92969 17.8887 9.1875 17.3203 9.70313 16.8398L16.0313 10.793C16.418 10.418 16.875 10.2305 17.4023 10.2305C17.918 10.2305 18.3428 10.4004 18.6768 10.7402C19.0107 11.0801 19.1777 11.5195 19.1777 12.0586C19.1777 12.5859 18.9727 13.0371 18.5625 13.4121L16.5059 15.293L14.6426 16.6641L18.2637 16.4355L26.2617 16.4355C26.8477 16.4355 27.3252 16.6377 27.6943 17.042C28.0635 17.4463 28.248 17.9473 28.248 18.5449C28.248 19.1426 28.0635 19.6436 27.6943 20.0479C27.3252 20.4521 26.8477 20.6543 26.2617 20.6543L18.2637 20.6543L14.625 20.4434L16.4531 21.7969L18.5625 23.6953C18.9727 24.0352 19.1777 24.4863 19.1777 25.0488C19.1777 25.5879 19.0107 26.0303 18.6768 26.376C18.3428 26.7217 17.918 26.8945 17.4023 26.8945C16.875 26.8945 16.418 26.7012 16.0313 26.3145L9.70313 20.2676C9.1875 19.7871 8.92969 19.2129 8.92969 18.5449Z"
                  id="Shape"
                  fill="#8A8A8F"
                  stroke="none"
                />
              </g>
            </svg>
            <div className="design-modal-header-toolbar-button-text mr15 ">
            Design
            </div>
          </div>
        </div>
      </div>

      <div className="design-modal-info">
        <div className="design-modal-info-logo">
          <img src={props.designDetial.iconImage} alt="logo" />
        </div>
        <div className="design-modal-info-text">
          <div className="design-modal-info-text-firstline">
            {props.designDetial.designName}
          </div>
          <div className="design-modal-info-text-secondline">
            {props.designDetial.designSubName}
          </div>
        </div>
      </div>
      <div></div>
      <div className="design-modal-header-photos">
        {props.designDetial.headerPhotos.map(
          (photo: DesignPhotoRow, i: number) => {
            return (
              <DesignRow
                hasDetail={false}
                openImage={null}
                key={i}
                photoRow={photo}
                index={i}
              />
            );
          }
        )}
      </div>
      <div className="design-modal-description">
        {props.designDetial.designDescription.map(
          (description: string, i: number) => {
            return (
              <p className="design-modal-description-text">{description}</p>
            );
          }
        )}
      </div>
      <div className="design-modal-footer-photos">
        {props.designDetial.footerPhotos.map(
          (photo: DesignPhotoRow, i: number) => {
            return (
              <DesignRow
                hasDetail={false}
                openImage={null}
                key={i}
                photoRow={photo}
                index={i}
              />
            );
          }
        )}
      </div>
    </section>
  );
}
