import React, { useRef, useEffect, useState, useLayoutEffect } from "react";

import Header from "../../components/header/header";
import Overlay from "../../components/overlay/overlay";
import About from "../../components/sections/about/about";
import Design from "../../components/sections/design/design";
import Photo from "../../components/sections/photo/photo";
import Splash from "../../components/sections/splash/splash";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Speaker from "../../components/sections/speaker/speaker";
import Writer from "../../components/sections/writer/writer";
import MotorRider from "../../components/sections/motorrider/motorrider";
import Logo from "../../assets/svg/logo";
import { SectionRef } from "../../interfaces/sectionRef";
const scrollTo = (ele: any) => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

interface IProps {}
interface IState {
  isLoaded: boolean;
  active: string;
  sectionRefs: SectionRef[];
  visibleSection: string;
}
gsap.registerPlugin(ScrollTrigger);
export default class Main extends React.Component<IProps, IState> {
  private headerRef: any;
  private humanRef: any;
  private motorRiderRef: any;
  private photoRef: any;
  private splashRef: any;
  private designRef: any;
  // private speakerRef = useRef(null);
  // private writerRef = useRef(null);
  private randomArray: any[];
  // private speakerRef = useRef(null);
  // private writerRef: any;

  constructor(props: any) {
    super(props);
    this.randomArray = [];
    this.headerRef = React.createRef();
    this.humanRef = React.createRef();
    this.motorRiderRef = React.createRef();
    this.photoRef = React.createRef();
    this.splashRef = React.createRef();
    this.designRef = React.createRef();
    // this.writerRef = React.createRef();

    this.state = {
      active: "",
      isLoaded: false,
      visibleSection: "",
      sectionRefs: [
        {
          sectionName: "splash",
          ref: this.splashRef,
          height: 0,
          heightFromTop: 0,
          className: "light",
          blurr: true,
        },
        {
          sectionName: "human",
          ref: this.humanRef,
          height: 0,
          heightFromTop: 0,
          className: "light",
          blurr: true,
        },
        {
          sectionName: "designer",
          ref: this.designRef,
          height: 0,
          heightFromTop: 0,
          className: "dark",
          blurr: false,
        },
        {
          sectionName: "photographer",
          ref: this.photoRef,
          height: 0,
          heightFromTop: 0,
          className: "designer",
          blurr: true,
        },
        {
          sectionName: "motorcyclist",
          ref: this.motorRiderRef,
          height: 0,
          heightFromTop: 0,
          className: "motorcyclist",
          blurr: false,
        },
        // { section: "speaker", ref: speakerRef, height: 0, heightFromTop: 0 },
        // {
        //   sectionName: "writer",
        //   ref: this.writerRef,
        //   height: 0,
        //   heightFromTop: 0,
        //   className: "dark",
        //   blurr: false,
        // },
      ],
    };
  }

  componentDidMount() {
    this.setState({ isLoaded: true });
    const sections = [
      this.humanRef,
      this.designRef,
      this.photoRef,
      this.motorRiderRef,
      // this.writerRef
    ].map((ref) => ref.current);
    if (this.randomArray.length < 1) {
      this.randomArray.push(
        ScrollTrigger.create({
          trigger: this.splashRef.current,
          start: "top top",
          pinSpacing: false,
          pin: true,
        })
      );
      sections.forEach((section) => {
        ScrollTrigger.create({
          trigger: section,
          start: "top top",
          end: "top top",
          pinSpacing: false,
          pin: true,
        });
        ScrollTrigger.create({
          trigger: section,
          start: "bottom bottom",
          pinSpacing: false,
          pin: true,
          anticipatePin: 0.2,
        });
      });
    }
    
  }

  HeaderClicked(sectionName: any) {
    const selectedRef = this.state.sectionRefs.find((obj) => {
      return obj.sectionName === sectionName;
    });
    if (selectedRef !== undefined && selectedRef !== null) {
      scrollTo(selectedRef.ref.current);
    }
  }

  OpenOverlay() {
    document.body.classList.add("noscroll");
    this.setState({ active: "menu-trigger " });
  }
  HideOverlay() {
    document.body.classList.remove("noscroll");
    this.setState({ active: "" });
  }

  ImageLoaded() {}

  render() {
    return (
      <div>
        <div className={`${this.state.active}`}>
          <Header
            refs={this.state.sectionRefs}
            name={this.state.visibleSection}
            openOverlay={this.OpenOverlay.bind(this)}
            refProp={this.headerRef}
          />
          <Splash
            navButtonHandler={this.HeaderClicked.bind(this)}
            refs={this.state.sectionRefs}
            refProp={this.splashRef}
          />
          <Overlay
            refs={this.state.sectionRefs}
            name={this.state.visibleSection}
            navButtonHandler={this.HeaderClicked.bind(this)}
            hideOverlay={this.HideOverlay.bind(this)}
          />
          <About refProp={this.humanRef} />
          <Design refProp={this.designRef} />
          <Photo
            refProp={this.photoRef}
            photosLoaded={this.ImageLoaded.bind(this)}
            loaded={this.state.isLoaded}
          />
          <MotorRider refProp={this.motorRiderRef} />
        {/* <Writer refProp={this.writerRef} />  */}
          {/* <Speaker refProp={speakerRef} />*/}
        </div>
      </div>
    );
  }
}
