import * as React from "react";
import { LightboxDefaultProps } from "../../types.js";
import { useContainerRect } from "../hooks/index.js";
import { createModule } from "../config.js";
import { clsx, cssClass, cssVar } from "../utils.js";
import { ImageSlide } from "../components/index.js";
import { useController } from "./Controller.js";
const CarouselSlide = ({ slide, offset }) => {
    const { setContainerRef, containerRect } = useContainerRect();
    const { latestProps } = useController();
    const { render } = latestProps.current;
    const renderSlide = (rect) => {
        var _a, _b, _c, _d;
        let rendered = (_a = render.slide) === null || _a === void 0 ? void 0 : _a.call(render, slide, offset, rect);
        if (!rendered && "src" in slide) {
            rendered = React.createElement(ImageSlide, { slide: slide, render: render, rect: rect });
        }
        return rendered ? (React.createElement(React.Fragment, null, (_b = render.slideHeader) === null || _b === void 0 ? void 0 :
            _b.call(render, slide),
            ((_c = render.slideContainer) !== null && _c !== void 0 ? _c : ((_, x) => x))(slide, rendered), (_d = render.slideFooter) === null || _d === void 0 ? void 0 :
            _d.call(render, slide))) : null;
    };
    return (React.createElement("div", { ref: setContainerRef, className: clsx(cssClass("slide"), cssClass("flex_center")), style: { [cssVar("slide_offset")]: offset } }, containerRect && renderSlide(containerRect)));
};
export const Carousel = ({ slides, carousel: { finite, preload, padding, spacing } }) => {
    const { currentIndex, globalIndex } = useController();
    const items = [];
    if ((slides === null || slides === void 0 ? void 0 : slides.length) > 0) {
        for (let i = currentIndex - preload; i < currentIndex; i += 1) {
            if (!finite || i >= 0) {
                items.push(React.createElement(CarouselSlide, { key: globalIndex + i - currentIndex, slide: slides[(i + preload * slides.length) % slides.length], offset: i - currentIndex }));
            }
        }
        items.push(React.createElement(CarouselSlide, { key: globalIndex, slide: slides[currentIndex], offset: 0 }));
        for (let i = currentIndex + 1; i <= currentIndex + preload; i += 1) {
            if (!finite || i <= slides.length - 1) {
                items.push(React.createElement(CarouselSlide, { key: globalIndex + i - currentIndex, slide: slides[i % slides.length], offset: i - currentIndex }));
            }
        }
    }
    return (React.createElement("div", { className: cssClass("carousel"), style: {
            ...(padding !== LightboxDefaultProps.carousel.padding
                ? { [cssVar("carousel_padding")]: padding }
                : null),
            ...(spacing !== LightboxDefaultProps.carousel.spacing
                ? { [cssVar("carousel_spacing")]: spacing !== 0 ? spacing : "0px" }
                : null),
        } }, items));
};
export const CarouselModule = createModule("carousel", Carousel);
