import { createContext, useState, useEffect } from "react";
import { SectionRef } from "../../interfaces/sectionRef";
export default function useHeightFromTop(refs: SectionRef[]) {
  const newArray: SectionRef[] = [];
  const [refsHeight, setRefsHeight] = useState(newArray);
  useEffect(() => {
    const handleHeightChanges = () => {
      if (refs[0].ref !== null && refs[0].ref.current !== null) {
        for (let index = 0; index < refs.length; index++) {
            const element = refs[index];
            element.height = element.ref.current.clientHeight;
            if(index === 1) {
              element.heightFromTop = refs[0].height
            }
            if (index> 1) {
              element.heightFromTop = refs[index - 1].heightFromTop + refs[index -1].height;
            }
            refs[index] = element;
          }
      }
      setRefsHeight(refs);
    };
    handleHeightChanges();
    window.addEventListener("scroll", handleHeightChanges);
    return () => {
      window.removeEventListener("scroll", handleHeightChanges);
    };
  }, []);
  return refsHeight;
}
