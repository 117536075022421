import * as React from "react";
export const createIcon = (name, glyph) => {
  const icon = (props) =>
    React.createElement(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 40 40",
        width: "40",
        height: "40",
        "aria-hidden": "true",
        focusable: "false",
        ...props,
      },
      React.createElement(
        "g",
        { fill: "currentColor" },
        glyph
      )
    );
  icon.displayName = name;
  return icon;
};
export const CloseIcon = createIcon(
  "Close",
  React.createElement("path", {
    d: "M18.5625 37.1426C21.1172 37.1426 23.5137 36.6592 25.752 35.6924C27.9902 34.7256 29.9619 33.3867 31.667 31.6758C33.3721 29.9648 34.7109 27.9902 35.6836 25.752C36.6563 23.5137 37.1426 21.1172 37.1426 18.5625C37.1426 16.0195 36.6563 13.6289 35.6836 11.3906C34.7109 9.15234 33.3721 7.17773 31.667 5.4668C29.9619 3.75586 27.9873 2.41699 25.7432 1.4502C23.499 0.483398 21.1055 0 18.5625 0C16.0195 0 13.626 0.483398 11.3818 1.4502C9.1377 2.41699 7.16309 3.75586 5.45801 5.4668C3.75293 7.17773 2.41699 9.15234 1.4502 11.3906C0.483398 13.6289 0 16.0195 0 18.5625C0 21.1172 0.483398 23.5137 1.4502 25.752C2.41699 27.9902 3.75586 29.9648 5.4668 31.6758C7.17773 33.3867 9.15234 34.7256 11.3906 35.6924C13.6289 36.6592 16.0195 37.1426 18.5625 37.1426ZM13.043 26.1035C12.4805 26.1035 12.0059 25.9072 11.6191 25.5146C11.2324 25.1221 11.0391 24.6445 11.0391 24.082C11.0391 23.543 11.2383 23.0801 11.6367 22.6934L15.7324 18.5801L11.6367 14.502C11.2383 14.1035 11.0391 13.6348 11.0391 13.0957C11.0391 12.5215 11.2324 12.0439 11.6191 11.6631C12.0059 11.2822 12.4805 11.0918 13.043 11.0918C13.6289 11.0918 14.1211 11.2852 14.5195 11.6719L18.5977 15.7324L22.6934 11.6543C23.0801 11.2676 23.5664 11.0742 24.1523 11.0742C24.7148 11.0742 25.1895 11.2676 25.5762 11.6543C25.9629 12.041 26.1563 12.5156 26.1563 13.0781C26.1563 13.6172 25.957 14.0859 25.5586 14.4844L21.4629 18.5801L25.541 22.6758C25.9512 23.0508 26.1563 23.5195 26.1563 24.082C26.1563 24.6445 25.9629 25.1221 25.5762 25.5146C25.1895 25.9072 24.709 26.1035 24.1348 26.1035C23.5371 26.1035 23.0449 25.9043 22.6582 25.5059L18.5977 21.4629L14.5547 25.5059C14.1563 25.9043 13.6523 26.1035 13.043 26.1035Z",
  })
);
export const PreviousIcon = createIcon(
  "Previous",
  React.createElement("path", {
    d: "M18.5625 37.1426C21.1172 37.1426 23.5137 36.6592 25.752 35.6924C27.9902 34.7256 29.9619 33.3867 31.667 31.6758C33.3721 29.9648 34.7109 27.9902 35.6836 25.752C36.6563 23.5137 37.1426 21.1172 37.1426 18.5625C37.1426 16.0195 36.6563 13.6289 35.6836 11.3906C34.7109 9.15234 33.3721 7.17773 31.667 5.4668C29.9619 3.75586 27.9873 2.41699 25.7432 1.4502C23.499 0.483398 21.1055 0 18.5625 0C16.0195 0 13.626 0.483398 11.3818 1.4502C9.1377 2.41699 7.16309 3.75586 5.45801 5.4668C3.75293 7.17773 2.41699 9.15234 1.4502 11.3906C0.483398 13.6289 0 16.0195 0 18.5625C0 21.1172 0.483398 23.5137 1.4502 25.752C2.41699 27.9902 3.75586 29.9648 5.4668 31.6758C7.17773 33.3867 9.15234 34.7256 11.3906 35.6924C13.6289 36.6592 16.0195 37.1426 18.5625 37.1426ZM14.4668 27.633C14.0801 27.2462 13.8926 26.7951 13.9043 26.2795C13.916 25.7638 14.1035 25.3302 14.4668 24.9787L21.3047 18.5802L14.4668 12.1994C14.0918 11.8478 13.9102 11.4054 13.9219 10.8722C13.9336 10.339 14.1328 9.89664 14.5196 9.54508C14.8594 9.21695 15.2783 9.05582 15.7764 9.06168C16.2744 9.06754 16.711 9.24625 17.086 9.59781L24.3457 16.383C24.7676 16.7697 25.0459 17.2326 25.1807 17.7716C25.3155 18.3107 25.3155 18.8498 25.1807 19.3888C25.0459 19.9279 24.7676 20.3966 24.3457 20.7951L17.086 27.5802C16.7461 27.9084 16.3037 28.0753 15.7588 28.0812C15.2139 28.0871 14.7832 27.9377 14.4668 27.633Z"
  })
);
export const NextIcon = createIcon(
  "Next",
  React.createElement("path", {
    d: "M18.5625 37.1426C21.1172 37.1426 23.5137 36.6592 25.752 35.6924C27.9902 34.7256 29.9619 33.3867 31.667 31.6758C33.3721 29.9648 34.7109 27.9902 35.6836 25.752C36.6563 23.5137 37.1426 21.1172 37.1426 18.5625C37.1426 16.0195 36.6563 13.6289 35.6836 11.3906C34.7109 9.15234 33.3721 7.17773 31.667 5.4668C29.9619 3.75586 27.9873 2.41699 25.7432 1.4502C23.499 0.483398 21.1055 0 18.5625 0C16.0195 0 13.626 0.483398 11.3818 1.4502C9.1377 2.41699 7.16309 3.75586 5.45801 5.4668C3.75293 7.17773 2.41699 9.15234 1.4502 11.3906C0.483398 13.6289 0 16.0195 0 18.5625C0 21.1172 0.483398 23.5137 1.4502 25.752C2.41699 27.9902 3.75586 29.9648 5.4668 31.6758C7.17773 33.3867 9.15234 34.7256 11.3906 35.6924C13.6289 36.6592 16.0195 37.1426 18.5625 37.1426ZM14.4668 27.633C14.0801 27.2462 13.8926 26.7951 13.9043 26.2795C13.916 25.7638 14.1035 25.3302 14.4668 24.9787L21.3047 18.5802L14.4668 12.1994C14.0918 11.8478 13.9102 11.4054 13.9219 10.8722C13.9336 10.339 14.1328 9.89664 14.5196 9.54508C14.8594 9.21695 15.2783 9.05582 15.7764 9.06168C16.2744 9.06754 16.711 9.24625 17.086 9.59781L24.3457 16.383C24.7676 16.7697 25.0459 17.2326 25.1807 17.7716C25.3155 18.3107 25.3155 18.8498 25.1807 19.3888C25.0459 19.9279 24.7676 20.3966 24.3457 20.7951L17.086 27.5802C16.7461 27.9084 16.3037 28.0753 15.7588 28.0812C15.2139 28.0871 14.7832 27.9377 14.4668 27.633Z"
  })
);
export const LoadingIcon = createIcon(
  "Loading",
  React.createElement(
    React.Fragment,
    null,
    Array.from({ length: 8 }).map((_, index, array) =>
      React.createElement("line", {
        key: index,
        x1: "12",
        y1: "6.5",
        x2: "12",
        y2: "1.8",
        strokeLinecap: "round",
        strokeWidth: "2.6",
        stroke: "currentColor",
        strokeOpacity: (1 / array.length) * (index + 1),
        transform: `rotate(${(360 / array.length) * index}, 12, 12)`,
      })
    )
  )
);
export const ErrorIcon = createIcon(
  "Error",
  React.createElement("path", {
    d: "M21.9,21.9l-8.49-8.49l0,0L3.59,3.59l0,0L2.1,2.1L0.69,3.51L3,5.83V19c0,1.1,0.9,2,2,2h13.17l2.31,2.31L21.9,21.9z M5,18 l3.5-4.5l2.5,3.01L12.17,15l3,3H5z M21,18.17L5.83,3H19c1.1,0,2,0.9,2,2V18.17z",
  })
);
