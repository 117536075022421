interface IProps {
    index: number;
    openImage: any;
    src: string;
  }
  
  export default function SvgImageMedium(props: IProps) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="349px" height="480px" viewBox="0 0 349 480" 
        onClick={() => props.openImage(props.index)}
      >
        <defs>
          <clipPath id="user-space-1" clipPathUnits="userSpaceOnUse">
          <path d="M10.3837355,1.39415293e-15 L338.616264,4.19515344e-16 C342.226916,-2.43750496e-16 343.536225,0.375943743 344.856225,1.0818875 C346.176225,1.78783126 347.212169,2.82377503 347.918112,4.1437751 C348.624056,5.46377516 349,6.77308448 349,10.3837355 L349,469.616264 C349,473.226916 348.624056,474.536225 347.918112,475.856225 C347.212169,477.176225 346.176225,478.212169 344.856225,478.918112 C343.536225,479.624056 342.226916,480 338.616264,480 L10.3837355,480 C6.77308448,480 5.46377516,479.624056 4.1437751,478.918112 C2.82377503,478.212169 1.78783126,477.176225 1.0818875,475.856225 C0.375943743,474.536225 -2.99888017e-15,473.226916 5.16132794e-15,469.616264 L2.79676896e-16,10.3837355 C-1.62500331e-16,6.77308448 0.375943743,5.46377516 1.0818875,4.1437751 C1.78783126,2.82377503 2.82377503,1.78783126 4.1437751,1.0818875 C5.46377516,0.375943743 6.77308448,-8.10043003e-16 10.3837355,1.39415293e-15 Z" id="path-1"></path>
          </clipPath>
        </defs>
        <image
          width="100%"
          height="100%"
          preserveAspectRatio="xMinYMin slice"
          xlinkHref={props.src}
          clipPath="url(#user-space-1)"
        />
      </svg>
    );
  }
  