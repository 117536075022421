import * as React from "react";
import { LightboxDefaultProps, LightboxPropTypes } from "./types.js";
import { CarouselModule, ControllerModule, CoreModule, createNode, NavigationModule, NoScrollModule, PortalModule, ToolbarModule, withPlugins, } from "./core/index.js";
const renderNode = (node, props) => {
    var _a;
    return React.createElement(node.module.component, { key: node.module.name, ...props }, (_a = node.children) === null || _a === void 0 ? void 0 : _a.map((child) => renderNode(child, props)));
};
const LightboxComponent = (props) => {
    const { plugins } = props;
    const { config, augmentation } = withPlugins([
        createNode(PortalModule, [
            createNode(NoScrollModule, [
                createNode(ControllerModule, [
                    createNode(CarouselModule),
                    createNode(ToolbarModule),
                    createNode(NavigationModule),
                ]),
            ]),
        ]),
    ], plugins);
    const augmentedProps = augmentation(props);
    if (!augmentedProps.open)
        return null;
    return React.createElement(React.Fragment, null, renderNode(createNode(CoreModule, config), augmentedProps));
};
LightboxComponent.propTypes = LightboxPropTypes;
export const Lightbox = (props) => {
    const { carousel, animation, render, toolbar, controller, on, ...restProps } = props;
    const { carousel: defaultCarousel, animation: defaultAnimation, render: defaultRender, toolbar: defaultToolbar, controller: defaultController, on: defaultOn, ...restDefaultProps } = LightboxDefaultProps;
    return (React.createElement(LightboxComponent, { carousel: { ...defaultCarousel, ...carousel }, animation: { ...defaultAnimation, ...animation }, render: { ...defaultRender, ...render }, toolbar: { ...defaultToolbar, ...toolbar }, controller: { ...defaultController, ...controller }, on: { ...defaultOn, ...on }, ...restDefaultProps, ...restProps }));
};
