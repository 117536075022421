import * as React from "react";
export const useSensors = () => {
    const [subscribers] = React.useState({});
    return React.useMemo(() => {
        const notifySubscribers = (type, event) => {
            var _a;
            (_a = subscribers[type]) === null || _a === void 0 ? void 0 : _a.forEach((listener) => listener(event));
        };
        return {
            registerSensors: {
                onPointerDown: (event) => notifySubscribers("onPointerDown", event),
                onPointerMove: (event) => notifySubscribers("onPointerMove", event),
                onPointerUp: (event) => notifySubscribers("onPointerUp", event),
                onPointerLeave: (event) => notifySubscribers("onPointerLeave", event),
                onPointerCancel: (event) => notifySubscribers("onPointerCancel", event),
                onTouchStart: (event) => notifySubscribers("onTouchStart", event),
                onTouchMove: (event) => notifySubscribers("onTouchMove", event),
                onTouchEnd: (event) => notifySubscribers("onTouchEnd", event),
                onTouchCancel: (event) => notifySubscribers("onTouchCancel", event),
                onKeyDown: (event) => notifySubscribers("onKeyDown", event),
                onKeyUp: (event) => notifySubscribers("onKeyUp", event),
                onWheel: (event) => notifySubscribers("onWheel", event),
            },
            subscribeSensors: (type, callback) => {
                if (!subscribers[type]) {
                    subscribers[type] = [];
                }
                subscribers[type].push(callback);
                return () => {
                    const listeners = subscribers[type];
                    if (listeners) {
                        listeners.splice(0, listeners.length, ...listeners.filter((el) => el !== callback));
                    }
                };
            },
        };
    }, [subscribers]);
};
