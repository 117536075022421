import * as React from "react";
import * as ReactDOM from "react-dom";
import { LightboxDefaultProps } from "../../types.js";
import { createModule } from "../config.js";
import { cssClass, cssVar } from "../utils.js";
import { useLatest } from "../hooks/useLatest.js";
import { useEvents, useTimeouts } from "../contexts/index.js";
export const Portal = ({ children, ...props }) => {
    const [mounted, setMounted] = React.useState(false);
    const latestProps = useLatest(props);
    const { setTimeout } = useTimeouts();
    const { subscribe } = useEvents();
    const [portal] = React.useState(() => {
        const div = document.createElement("div");
        div.className = cssClass("portal");
        const fadeAnimation = latestProps.current.animation.fade;
        if (fadeAnimation !== LightboxDefaultProps.animation.fade) {
            div.style.setProperty(cssVar("fade_animation_duration"), `${Math.round(fadeAnimation)}ms`);
        }
        return div;
    });
    React.useEffect(() => subscribe("close", () => {
        var _a, _b;
        (_b = (_a = latestProps.current.on).exiting) === null || _b === void 0 ? void 0 : _b.call(_a);
        portal.classList.remove(cssClass("portal_open"));
        setTimeout(() => {
            var _a, _b;
            (_b = (_a = latestProps.current.on).exited) === null || _b === void 0 ? void 0 : _b.call(_a);
            latestProps.current.close();
        }, latestProps.current.animation.fade);
    }), [portal, setTimeout, subscribe, latestProps]);
    React.useEffect(() => {
        var _a, _b;
        document.body.appendChild(portal);
        setMounted(true);
        (_b = (_a = latestProps.current.on).entering) === null || _b === void 0 ? void 0 : _b.call(_a);
        setTimeout(() => {
            portal.classList.add(cssClass("portal_open"));
        }, 0);
        setTimeout(() => {
            var _a, _b;
            (_b = (_a = latestProps.current.on).entered) === null || _b === void 0 ? void 0 : _b.call(_a);
        }, latestProps.current.animation.fade);
        return () => {
            document.body.removeChild(portal);
            setMounted(false);
        };
    }, [portal, setTimeout, latestProps]);
    return ReactDOM.createPortal(mounted ? children : null, portal);
};
export const PortalModule = createModule("portal", Portal);
