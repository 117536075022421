import * as React from "react";
export const clsx = (...classes) => [...classes].filter((cls) => Boolean(cls)).join(" ");
const cssPrefix = "yarl__";
export const cssClass = (name) => `${cssPrefix}${name}`;
export const cssVar = (name) => `--${cssPrefix}${name}`;
export const label = (labels, lbl) => (labels && labels[lbl] ? labels[lbl] : lbl);
export const cleanup = (...cleaners) => () => {
    cleaners.forEach((cleaner) => {
        cleaner();
    });
};
export const makeUseContext = (name, contextName, context) => () => {
    const ctx = React.useContext(context);
    if (!ctx) {
        throw new Error(`${name} must be used within a ${contextName}.Provider`);
    }
    return ctx;
};
