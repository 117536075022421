import * as React from "react";
export const useContainerRect = () => {
    const [containerRect, setContainerRect] = React.useState();
    const containerRef = React.useRef(null);
    const observerRef = React.useRef();
    const setContainerRef = React.useCallback((node) => {
        containerRef.current = node;
        if (observerRef.current) {
            observerRef.current.disconnect();
            observerRef.current = undefined;
        }
        const updateContainerRect = () => {
            const width = node === null || node === void 0 ? void 0 : node.clientWidth;
            const height = node === null || node === void 0 ? void 0 : node.clientHeight;
            setContainerRect(width !== undefined && height !== undefined
                ? {
                    width,
                    height,
                }
                : undefined);
        };
        updateContainerRect();
        if (node && typeof ResizeObserver !== "undefined") {
            observerRef.current = new ResizeObserver(updateContainerRect);
            observerRef.current.observe(node);
        }
    }, []);
    return React.useMemo(() => ({
        setContainerRef,
        containerRef,
        containerRect,
    }), [setContainerRef, containerRef, containerRect]);
};
