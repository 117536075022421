import * as React from "react";
import { makeUseContext } from "../utils.js";
const EventsContext = React.createContext(null);
export const useEvents = makeUseContext("useEvents", "EventsContext", EventsContext);
export const EventsProvider = ({ children }) => {
    const subscriptions = React.useRef({});
    const unsubscribe = (topic, callback) => {
        if (subscriptions.current[topic]) {
            subscriptions.current[topic] = subscriptions.current[topic].filter((cb) => cb !== callback);
        }
    };
    const subscribe = (topic, callback) => {
        if (!subscriptions.current[topic]) {
            subscriptions.current[topic] = [];
        }
        subscriptions.current[topic].push(callback);
        return () => unsubscribe(topic, callback);
    };
    const publish = (topic, event) => {
        var _a;
        (_a = subscriptions.current[topic]) === null || _a === void 0 ? void 0 : _a.forEach((callback) => callback(topic, event));
    };
    React.useEffect(() => () => {
        subscriptions.current = {};
    }, []);
    const context = React.useRef({
        subscribe,
        unsubscribe,
        publish,
    });
    return React.createElement(EventsContext.Provider, { value: context.current }, children);
};
