import { useInView } from "react-intersection-observer";
import "./about.scss";

export default function About(props: any) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.09,
  });

  return (
    <section className="about" ref={props.refProp} id="human">
      <div
        className={
          inView ? "section-body-about is-intersecting" : "section-body-about "
        }
        ref={ref}
      >
        <div className="section-photo">
          <img src="static/img/sebastiaan.png" alt="A picture of Sebastiaan" />
        </div>
        <div className="section-body-about-content">
          <div className="section-body-about-content-hi">
            <img src="static/img/hi.png" alt="text in a bubble that says hi" />
          </div>
          <div className="section-body-about-content-text">
            <strong className="section-body-about-content-text-large">
              I’m Sebastiaan de With. I am a human living in two beautiful
              cities on Earth: San Francisco and Amsterdam.
            </strong>
            <p>
              Welcome to my website. I share some of my creative work here.{" "}
            </p>
            <p>
              I’m the co-founder and designer at{" "}
              <a href="http://lux.camera">Lux Optics</a>, a small company making
              exceptional, award-winning cameras and photography apps for iPhone
              and iPad.
            </p>
            <p>
              I also work as a freelance photographer, specializing in
              motorcycle, adventure and lifestyle photography.{" "}
            </p>
            <p>
              I previously worked as head of design at Doubletwist and as a
              designer at Apple. As a freelance designer, I designed user
              interfaces and icons for Sony, Mozilla, Oracle, Intel, AMD, frog
              design, Publicis, HP, EA, TomTom and many other wonderful
              companies and people.
            </p>
            <p>
              Besides my work, I love reading, nature, hiking, history,
              entheogen research, environmental justice, and humanity.
            </p>
            <div className="section-body-about-content-contact">
              <div className="section-body-about-content-contact">
                Stay in touch:
              </div>
              <a
                className="section-body-about-content-contact-email"
                href="mailto:s@dewith.com"
              >
                email
              </a>
              <a
                className="section-body-about-content-contact-twitter"
                href="https://twitter.com/sdw"
              >
                twitter
              </a>
              <a
                className="section-body-about-content-contact-instagram"
                href="https://instagram.com/sdw"
              >
                instagram
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
