interface IProps {
    index: number;
    openImage: any;
    src: string;
  }
  
  export default function SvgImageLarge(props: IProps) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="547px" height="736px" viewBox="0 0 547 736"
        onClick={() => props.openImage(props.index)}
      >
        <defs>
          <clipPath id="user-space-0" clipPathUnits="userSpaceOnUse">
          <path d="M10.9606095,-8.60432899e-15 L536.039391,4.42821741e-16 C539.850633,-2.57292184e-16 541.232682,0.396829497 542.626015,1.14199233 C544.019349,1.88715517 545.112845,2.98065135 545.858008,4.37398471 C546.603171,5.76731808 547,7.14936678 547,10.9606095 L547,725.039391 C547,728.850633 546.603171,730.232682 545.858008,731.626015 C545.112845,733.019349 544.019349,734.112845 542.626015,734.858008 C541.232682,735.603171 539.850633,736 536.039391,736 L10.9606095,736 C7.14936678,736 5.76731808,735.603171 4.37398471,734.858008 C2.98065135,734.112845 1.88715517,733.019349 1.14199233,731.626015 C0.396829497,730.232682 4.38670212e-15,728.850633 -7.54988761e-15,725.039391 L2.95214494e-16,10.9606095 C-1.71528123e-16,7.14936678 0.396829497,5.76731808 1.14199233,4.37398471 C1.88715517,2.98065135 2.98065135,1.88715517 4.37398471,1.14199233 C5.76731808,0.396829497 7.14936678,4.99936293e-15 10.9606095,-8.60432899e-15 Z" id="path-1"></path>
          </clipPath>
        </defs>
        <image
          width="100%"
          height="100%"
          preserveAspectRatio="xMinYMin slice"
          xlinkHref={props.src}
          clipPath="url(#user-space-0)"
        />
      </svg>
    );
  }
  