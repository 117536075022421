import * as React from "react";
import { createModule } from "../config.js";
import { cssClass, label as translateLabel } from "../utils.js";
import { IconButton, NextIcon, PreviousIcon } from "../components/index.js";
import { useEvents } from "../contexts/index.js";
import { useController } from "./Controller.js";
export const NavigationButton = ({ publish, labels, label, icon, renderIcon, action, disabled, }) => (React.createElement(IconButton, { label: translateLabel(labels, label), icon: icon, renderIcon: renderIcon, className: cssClass(`navigation_${action}`), disabled: disabled, "aria-disabled": disabled, onClick: () => {
        publish(action);
    } }));
export const Navigation = ({ slides, carousel: { finite }, labels, render: { buttonPrev, buttonNext, iconPrev, iconNext }, }) => {
    const { currentIndex, subscribeSensors } = useController();
    const { publish } = useEvents();
    React.useEffect(() => subscribeSensors("onKeyUp", (event) => {
        if (event.code === "ArrowLeft") {
            publish("prev");
        }
        else if (event.code === "ArrowRight") {
            publish("next");
        }
    }), [subscribeSensors, publish]);
    return (React.createElement(React.Fragment, null,
        buttonPrev ? (buttonPrev()) : (React.createElement(NavigationButton, { label: "Previous", action: "prev", icon: PreviousIcon, renderIcon: iconPrev, disabled: finite && currentIndex === 0, labels: labels, publish: publish })),
        buttonNext ? (buttonNext()) : (React.createElement(NavigationButton, { label: "Next", action: "next", icon: NextIcon, renderIcon: iconNext, disabled: finite && currentIndex === slides.length - 1, labels: labels, publish: publish }))));
};
export const NavigationModule = createModule("navigation", Navigation);
