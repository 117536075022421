import * as React from "react";
import { clsx, cssClass } from "../utils.js";
import { useLatest } from "../hooks/index.js";
import { ErrorIcon, LoadingIcon } from "./Icons.js";
export const ImageSlide = ({ slide: image, render, rect }) => {
    var _a, _b, _c;
    const [state, setState] = React.useState("loading");
    const latestState = useLatest(state);
    const imageRef = React.useRef(null);
    const handleLoading = React.useCallback((img) => {
        if (latestState.current === "complete") {
            return;
        }
        ("decode" in img ? img.decode() : Promise.resolve())
            .catch(() => { })
            .then(() => {
            if (!img.parentNode) {
                return;
            }
            setState("complete");
        });
    }, [latestState]);
    const setImageRef = React.useCallback((img) => {
        imageRef.current = img;
        if (img === null || img === void 0 ? void 0 : img.complete) {
            handleLoading(img);
        }
    }, [handleLoading]);
    const onLoad = React.useCallback((event) => {
        handleLoading(event.currentTarget);
    }, [handleLoading]);
    const onError = React.useCallback(() => {
        setState("error");
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("img", { ref: setImageRef, onLoad: onLoad, onError: onError, className: clsx(cssClass("slide_image"), state !== "complete" && cssClass("slide_image_loading")), draggable: false, alt: image.alt, ...(image.srcSet
                ? {
                    ...(rect && typeof window !== "undefined"
                        ? {
                            sizes: `${Math.ceil((Math.min(image.aspectRatio ? rect.height * image.aspectRatio : Number.MAX_VALUE, rect.width) /
                                window.innerWidth) *
                                100)}vw`,
                        }
                        : null),
                    srcSet: image.srcSet
                        .sort((a, b) => a.width - b.width)
                        .map((item) => `${item.src} ${item.width}w`)
                        .join(", "),
                    style: {
                        maxWidth: `${Math.max(...image.srcSet.map((x) => x.width))}px`,
                    },
                }
                : {
                    style: ((_b = (_a = imageRef.current) === null || _a === void 0 ? void 0 : _a.naturalWidth) !== null && _b !== void 0 ? _b : 0) > 0
                        ? {
                            maxWidth: `${(_c = imageRef.current) === null || _c === void 0 ? void 0 : _c.naturalWidth}px`,
                        }
                        : undefined,
                }), src: image.src }),
        state !== "complete" && (React.createElement("div", { className: cssClass("slide_placeholder") },
            state === "loading" &&
                ((render === null || render === void 0 ? void 0 : render.iconLoading) ? (render.iconLoading()) : (React.createElement(LoadingIcon, { className: clsx(cssClass("icon"), cssClass("slide_loading")) }))),
            state === "error" &&
                ((render === null || render === void 0 ? void 0 : render.iconError) ? (render.iconError()) : (React.createElement(ErrorIcon, { className: clsx(cssClass("icon"), cssClass("slide_error")) })))))));
};
