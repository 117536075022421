import { DesignPhotoRow } from "../../interfaces/DesignPhotoRow";
import './designRow.scss'
interface IProps {
  photoRow: DesignPhotoRow;
  openImage: any;
  hasDetail: boolean;
  index: number;
}

export function DesignRow(props: IProps) {
  if (props.photoRow.type === "large") {
    return (
      <div className="design-row">
        <div className="large-design h100">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[0].src}
            alt={props.photoRow.photos[0].alt}
            loading="lazy"
            width={1100}
            height={774}
          />
        </div>
      </div>
    );
  }
  if (props.photoRow.type === "medium") {
    return (
      <div className="design-row">
        <div className="medium-design jstart">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[0].src}
            alt={props.photoRow.photos[0].alt}
            loading="lazy"
            width={528}
            height={390}
          />
        </div>
        <div className="medium-design jend">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[1].src}
            alt={props.photoRow.photos[1].alt}
            loading="lazy"
            width={528}
            height={390}
          />
        </div>
      </div>
    );
  }
  if (props.photoRow.type === "triple-small") {
    return (
      <div className="design-row">
        <div className="small-design">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[0].src}
            alt={props.photoRow.photos[0].alt}
            width={348}
            height={363}
            loading="lazy"
          />
        </div>
        <div className="small-design">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[1].src}
            alt={props.photoRow.photos[1].alt}
            width={348}
            height={363}
            loading="lazy"
          />
        </div>
        <div className="small-design">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[2].src}
            alt={props.photoRow.photos[2].alt}
            width={348}
            height={363}
            loading="lazy"
          />
        </div>
      </div>
    );
  }
  if (props.photoRow.type === "icon-with-photo") {
    return (
      <div className="design-row">
        <div className="small-design">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[0].src}
            alt={props.photoRow.photos[0].alt}
            width={348}
            height={363}
            loading="lazy"
          />
        </div>
        <div className="small-medium-design ">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[1].src}
            alt={props.photoRow.photos[1].alt}
            width={715}
            height={363}
            loading="lazy"
          />
        </div>
      </div>
    );
  }
  if (props.photoRow.type === "icon-with-photo-reverse") {
    return (
      <div className="design-row">
        <div className="small-medium-design ">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[0].src}
            alt={props.photoRow.photos[0].alt}
            width={715}
            height={363}
            loading="lazy"
          />
        </div>
        <div className="small-design">
          <img
            onClick={props.hasDetail ? ()=>{props.openImage(props.index)} : undefined }
            src={props.photoRow.photos[1].src}
            alt={props.photoRow.photos[1].alt}
            width={348}
            height={363}
            loading="lazy"
          />
        </div>
      </div>
    );
  }
  return <h2>SEBASTIAAN, JE HEBT EEN FOUT GEMAAKT</h2>;
}
