interface IProps {
  index: number;
  openImage: any;
  src: string;
}

export default function SvgImageSmall(props: IProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 253 356"
      onClick={() => props.openImage(props.index)}
    >
      <defs>
        <clipPath id="user-space-2" clipPathUnits="userSpaceOnUse">
          <path
            d="M5.48030474,2.49909155e-15 L247.519695,2.21410871e-16 C249.425317,-1.28646092e-16 250.116341,0.198414748 250.813008,0.570996167 C251.509674,0.943577586 252.056422,1.49032568 252.429004,2.18699236 C252.801585,2.88365904 253,3.57468339 253,5.48030474 L253,350.519695 C253,352.425317 252.801585,353.116341 252.429004,353.813008 C252.056422,354.509674 251.509674,355.056422 250.813008,355.429004 C250.116341,355.801585 249.425317,356 247.519695,356 L5.48030474,356 C3.57468339,356 2.88365904,355.801585 2.18699236,355.429004 C1.49032568,355.056422 0.943577586,354.509674 0.570996167,353.813008 C0.198414748,353.116341 6.12660811e-16,352.425317 -1.05444139e-15,350.519695 L1.47607247e-16,5.48030474 C-8.57640614e-17,3.57468339 0.198414748,2.88365904 0.570996167,2.18699236 C0.943577586,1.49032568 1.49032568,0.943577586 2.18699236,0.570996167 C2.88365904,0.198414748 3.57468339,-1.45204416e-15 5.48030474,2.49909155e-15 Z"
            id="path-2"
          ></path>
        </clipPath>
      </defs>
      <image
        width="100%"
        height="100%"
        preserveAspectRatio="xMinYMin slice"
        xlinkHref={props.src}
        clipPath="url(#user-space-2)"
      />
    </svg>
  );
}
