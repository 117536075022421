import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { arrayBuffer } from "stream/consumers";
import Logo from "../../assets/svg/logo";
import Header from "../../components/header/header";
import Overlay from "../../components/overlay/overlay";
import SvgImageSmall from "../../components/svgImage/svgImageSmall";
import { PhotoSet } from "../../interfaces/photoSet";
import { SectionRef } from "../../interfaces/sectionRef";
import "./photos.scss";

interface IProps {}
interface IState {
  sectionRefs: SectionRef[];
  active: string;
  visibleSection: string;
  otherArray: PhotoSet[];
  loading: boolean;
}
export default class Photos extends React.Component<IProps, IState> {
  private headerRef: any;
  constructor(props: any) {
    super(props);
    this.headerRef = React.createRef();

    // this.writerRef = React.createRef();

    this.state = {
      loading: true,
      active: "",
      visibleSection: "",
      otherArray: [],
      sectionRefs: [],
    };
  }

  async componentDidMount() {
    let newPhotoSetArray: any = [];
    await fetch("http://sdw.space/server/images.php")
      .then((res) => res.json())
      .then(
        async (result) => {
          for (const [key, value] of Object.entries(result)) {
            if (Array.isArray(value)) {
              let photoSet: PhotoSet = {
                title: "",
                description: "",
                images: [],
              };
              let containsInfoJson = false;
              for (let index = 0; index < value.length; index++) {
                const element = value[index];
                if (element.includes("info.json")) {
                  await fetch(element)
                    .then((res) => res.json())
                    .then((res) => {
                      if (
                        res.title !== undefined &&
                        res.description !== undefined &&
                        res.title !== null &&
                        res.description !== null &&
                        !containsInfoJson
                      ) {
                        containsInfoJson = true;
                        photoSet.title = res.title;
                        photoSet.description = res.description;
                      }
                    });
                }
                if (
                  element.includes(".jpg") ||
                  element.includes(".png") ||
                  element.includes(".jpeg")
                ) {
                  photoSet.images.push(element);
                }
                if (
                  index + 1 === value.length &&
                  containsInfoJson &&
                  photoSet.images.length > 0
                ) {
                  newPhotoSetArray.push(photoSet);
                }
              }
            }
          }
        },
        (error) => {}
      );
    this.setState({ otherArray: newPhotoSetArray, loading: false });
  }

  HeaderClicked(sectionName: any) {}

  OpenOverlay() {
    document.body.classList.add("noscroll");
    this.setState({ active: "menu-trigger " });
  }
  HideOverlay() {
    document.body.classList.remove("noscroll");
    this.setState({ active: "" });
  }

  render() {
    const loading = this.state.loading;
    const photoSets = this.state.otherArray;
    return (
      <div>
        <section className="header header-active">
          <div className={`header-blurr`}></div>
          <div className={`header-background light`}></div>

          <div className={"section-body-header"}>
            <div className={`name small ${"light"}`}>sdw</div>
            <div className="section-body-header-logo-holder">
              <Logo white={""} />
            </div>
            <div className={`tag small ${"light"}`}>
              <span>Photography</span>
            </div>
          </div>
        </section>

        {loading && (
          <div className="spinner-container">
            <svg
              version="1.1"
              viewBox="0 0 651 651"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>sdwbal@2x</title>
              <defs>
                <polygon id="a" points="0 0 651 0 651 651 0 651" />
              </defs>
              <g fill="none" fillRule="evenodd">
                <path
                  id="line1"
                  pathLength="1"
                  d="m325.5 35.409c-159.96 0-290.09 130.13-290.09 290.09 0 159.96 130.13 290.09 290.09 290.09 159.96 0 290.09-130.13 290.09-290.09 0-159.96-130.13-290.09-290.09-290.09m0 615.59c-179.48 0-325.5-146.02-325.5-325.5s146.02-325.5 325.5-325.5c179.48 0 325.5 146.02 325.5 325.5s-146.02 325.5-325.5 325.5"
                  fill="#231F20"
                  mask="url(#b)"
                />
                <path
                  id="line2"
                  pathLength="1"
                  d="m167.74 241.44c-37.781 0-67.699-23.103-78.08-60.293-10.666-38.214 0.985-93.346 51.769-124.09l18.338 30.291c-34.985 21.179-43.186 58.534-36 84.279 2.887 10.343 12.951 34.403 43.973 34.403 43.586 0 68.013-37.768 96.296-81.501 32.048-49.551 68.37-105.71 141.2-105.71 37.269 0 64.22 10.417 110.92 42.866l-20.205 29.079c-44.985-31.26-64.343-36.535-90.713-36.535-53.561 0-81.688 43.49-111.47 89.535-31.057 48.021-63.173 97.679-126.03 97.679"
                  fill="#231F20"
                />
                <path
                  id="line3"
                  pathLength="1"
                  d="m213.49 544.69c-61.78 0-115.17-21.12-154.39-61.078-35.499-36.165-56.252-84.911-58.433-137.26l35.377-1.474c1.817 43.569 18.979 84.027 48.327 113.92 32.41 33.021 77.058 50.473 129.12 50.473 69.262 0 98.577-30.276 132.52-65.334 31.187-32.213 66.536-68.721 135.58-74.802 56.356-4.966 94.751-26.171 111.04-61.32 18.179-39.235 7.645-96.11-26.836-144.9l28.919-20.437c42.302 59.859 53.817 128.92 30.047 180.22-15.284 32.992-51.989 73.948-140.06 81.707-55.893 4.926-82.474 32.375-113.25 64.161-34.545 35.675-73.697 76.112-157.96 76.112"
                  fill="#231F20"
                />
                <path
                  id="line4"
                  pathLength="1"
                  d="m455.52 589.16c-19.399 0-38.043-4.174-54.46-12.821-7.797-4.108-14.426-8.081-20.839-11.925-29.807-17.872-51.343-30.789-150.62-19.825l-3.886-35.197c105.76-11.669 134.53 1.761 172.72 24.65 5.998 3.599 12.201 7.318 19.13 10.968 35.25 18.571 88.389 6.604 126.34-28.465l24.03 26.01c-32.798 30.299-74.078 46.605-112.42 46.605"
                  fill="#231F20"
                />
                <path
                  id="line5"
                  pathLength="1"
                  d="m295.5 530.51-15.391-31.888c75.901-36.633 128.21-54.365 187.17-43.201l5.322 1.011c67.891 12.925 104.48 16.904 129.34-42.583l32.67 13.656c-37.096 88.75-106.98 75.447-168.63 63.714l-5.287-1.005c-49.327-9.34-96.118 6.955-165.2 40.296"
                  fill="#231F20"
                />
                <path
                  id="line6"
                  pathLength="1"
                  d="m210.48 444.81c-81.674 0-124.61-41.115-146.24-75.604-38.896-62.006-41.614-155.88-6.61-228.29l31.879 15.412c-25.306 52.347-31.809 135.81 4.727 194.06 24.562 39.157 63.675 59.012 116.25 59.012 34.685 0 64.984-12.734 85.319-35.855 21.881-24.879 30.694-59.399 25.489-99.831-8.323-64.645 2.207-114.19 31.296-147.27 24.783-28.179 61.482-42.465 109.08-42.465 50.337 0 119.57 28.751 152.64 91.877l-31.366 16.43c-26.236-50.085-81.254-72.897-121.27-72.897-36.974 0-64.729 10.242-82.494 30.44-21.761 24.742-29.632 66.017-22.764 119.36 6.45 50.096-5.632 95.462-34.021 127.74-27.155 30.877-66.897 47.88-111.91 47.88"
                  fill="#231F20"
                />
                <path
                  id="line7"
                  pathLength="1"
                  d="m192.56 358.56c-51.444 0-94.977-18.208-122.58-51.266-26.751-32.04-36.879-75.066-28.52-121.16l34.84 6.32c-6.453 35.577 0.957 68.3 20.861 92.14 21.058 25.221 54.045 38.551 95.399 38.551 68.559 0 111.19-44.111 130.32-134.85l34.649 7.306c-22.801 108.13-78.304 162.96-164.97 162.96"
                  fill="#231F20"
                />
              </g>
            </svg>
          </div>
        )}
        {!loading && (
          <section className="photo-page">
            <div className="section-body-photo-page">
              {photoSets.map((value: PhotoSet, index: any) => {
                return (
                  <div className={index % 2 == 0 ? "photo-set-row" : "photo-set-row reverse"}>
                        <div className="photo-set-row-thumbs columflex">
                          <div className="photo-set-row-thumbs-line">
                            <div className="photo-set-row-thumbs-line-thumbnail">
                              <SvgImageSmall
                                index={1}
                                src={value.images[1]}
                                openImage={null}
                              />
                            </div>
                            <div className="photo-set-row-thumbs-line-thumbnail">
                              <SvgImageSmall
                                index={2}
                                src={value.images[2]}
                                openImage={null}
                              />
                            </div>
                          </div>
                          <div className="photo-set-row-thumbs-line">
                            <div className="photo-set-row-thumbs-line-thumbnail">
                              <SvgImageSmall
                                index={3}
                                src={value.images[3]}
                                openImage={null}
                              />
                            </div>
                            <div className="photo-set-row-thumbs-line-thumbnail">
                              <SvgImageSmall
                                index={4}
                                src={value.images[4]}
                                openImage={null}
                              />
                            </div>
                          </div>
                    </div>
                    <div className="photo-set-row-info">
                      <div className="photo-set-row-info-title">
                        {value.title}
                      </div>
                      <div className="photo-set-row-info-description">
                        {value.description}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}
      </div>
    );
  }
}
