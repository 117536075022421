import { useInView } from "react-intersection-observer";
import "./motorrider.scss";

export default function MotorRider(props: any) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.09,
  });
  return (
    <section className="motor " ref={props.refProp} id="motorcyclist">
      <div className="section-photo">
        <img src="static/img/IMG_2489.jpg" loading="lazy" width={2048} height={1364} alt="Sebastiaan on a motorcycle" />
      </div>
      <div
        className={
          inView ? "section-body-motor is-intersecting" : "section-body-motor "
        }
        ref={ref}
      >
        <div className="section-body-motor-logos">
          <img
            src="static/img/Group 316.png"
            alt="Sebastiaan on a motorcycle"
          />
          <img src="static/img/Fill 1.png" alt="Sebastiaan on a motorcycle" />
        </div>
        <div className="section-body-motor-text-holder">
          <div className="section-body-motor-text">
            I ride motorcycles around the world — sometimes as part of a longer
            personal journey, like my Ride Earth project, or sometimes as part
            of a rally or race.
            <p>
              I also work as a photographer for motorcycle, motorcycle apparel
              and lifestyle brands.
            </p>
            <p>Notable rides: </p>
            <h2>
              <a href="https://imgur.io/gallery/J7kZJ">
                2014 — San Francisco to Alaska
              </a>
            </h2>
            A three-month adventure with my friend Stuart Philkill. Featured on
            CNN, People Magazine, The Blaze, and many others; Reddit Top Post of
            2014.
            <h2>
              <a href="http://instagram.com/rideearth">
                2016 — San Francisco to Costa Rica
              </a>
            </h2>
            Another four-month adventure with Stuart Philkill, this time
            charting a course for South America, ended early in Costa Rica.
            <h2>
              <a href="http://instagram.com/rideearth">
                2019-Current — San Francisco to Argentina (via Canada)
              </a>
            </h2>
            A three month adventure to ride motorcylces up to Vancouver, Canada
            from San Francisco and ship them to Bogota, Colombia to attempt a
            ride to Tierra del Fuego, Argentina. On pause due to COVID-19.
            <h2>
              <a href="https://eu.deuscustoms.com/blogs/blog/the-swank-rally-diary">
                2021 — Deus Swank Rally di Sardegna (Finished, Non-Competitive
                Class)
              </a>
            </h2>
            Co-founded the Amsterdam-based Humilis Racing team with Roland
            Peelen and rode the 4-day rally from Milan to Sardinia. Sponsored by
            Deus Ex Machina, Leica Camera, Alpinestars and De Rijke & Co
            — providing images to Deus Ex Machina to capture the journey from an
            insiders point of view.
            <h2>
              <a href="https://www.mezcalmotorally.com">
                2022 — Mezcal Moto Rally (Finished First Place)
              </a>
            </h2>
            Rode in the 3-day Mezcal Moto Rally from Austin, Texas to Oaxaca,
            Mexico and finished first place.
          </div>
        </div>
      </div>
    </section>
  );
}
