import "./design.scss";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import designs from "./data.json";
import LazyLoad from "react-lazyload";
import { DesignDetial } from "../../../interfaces/designDetail";
import { DesignRow } from "../../designRow/designRow";
import ReactModal from "react-modal";
import { DesignDetailModal } from "../../designModal/designDetailModal";
export default function Design(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState(-1);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.09,
  });
  const desings: DesignDetial[] = designs;
  const openImage = (number: number) => {
  setIndex(number);
  setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    };
ReactModal.setAppElement('#root');
  

  return (
    <section className="design" ref={props.refProp} id="designer">
      <div
        className={
          inView
            ? "section-body-design is-intersecting"
            : "section-body-design "
        }
        ref={ref}
      >
        {desings.map((design: DesignDetial, i: number) => {
          let rows: any = [];
          design.designOverViewPhotos.forEach((element: any, y: number) => {
            rows.push(
              <DesignRow
                hasDetail={design.hasDetail}
                openImage={openImage}
                key={i * y + i + y}
                photoRow={element}
                index={i}
              />
            );
          });
          return rows;
        })}
        <div className="design-row jcc fdc">
          <div className="design-row-text">
            previously designed visual identities, icons and user interfaces
            for:
          </div>
          <div className="large-design h100 mt50">
            <img src={"static/img/designs/logos-1746.png"} alt={"showing all the brands sebastiaan has worked on"} />
          </div>
        </div>
      </div>
      <ReactModal 
           isOpen={showModal}
           contentLabel="onRequestClose Example"
           onRequestClose={closeModal}
           className="Modal"
           overlayClassName="Overlay"
        >
          <DesignDetailModal closeModal={closeModal} designDetial={designs[index]} />
        </ReactModal>
    </section>
  );
}
