import PropTypes from "prop-types";
export const ImageSlidePropTypes = PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    aspectRatio: PropTypes.number,
    srcSet: PropTypes.arrayOf(PropTypes.shape({
        src: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
    }).isRequired),
});
export const SlideTypesPropTypes = [ImageSlidePropTypes];
export const LightboxPropTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    slides: PropTypes.arrayOf(PropTypes.oneOfType(SlideTypesPropTypes).isRequired).isRequired,
    render: PropTypes.shape({
        slide: PropTypes.func,
        slideHeader: PropTypes.func,
        slideFooter: PropTypes.func,
        slideContainer: PropTypes.func,
        iconPrev: PropTypes.func,
        iconNext: PropTypes.func,
        iconClose: PropTypes.func,
        iconLoading: PropTypes.func,
        iconError: PropTypes.func,
        buttonPrev: PropTypes.func,
        buttonNext: PropTypes.func,
        buttonClose: PropTypes.func,
    }).isRequired,
    plugins: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
    toolbar: PropTypes.shape({
        buttons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.oneOf(["close"]), PropTypes.node])).isRequired,
    }).isRequired,
    labels: PropTypes.shape({}).isRequired,
    carousel: PropTypes.shape({
        finite: PropTypes.bool.isRequired,
        preload: PropTypes.number.isRequired,
        padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }).isRequired,
    animation: PropTypes.shape({
        fade: PropTypes.number.isRequired,
        swipe: PropTypes.number.isRequired,
    }).isRequired,
    controller: PropTypes.shape({
        focus: PropTypes.bool.isRequired,
    }).isRequired,
    on: PropTypes.shape({
        view: PropTypes.func,
        entering: PropTypes.func,
        entered: PropTypes.func,
        exiting: PropTypes.func,
        exited: PropTypes.func,
    }).isRequired,
};
export const LightboxDefaultProps = {
    open: false,
    close: () => { },
    index: 0,
    slides: [],
    render: {},
    plugins: [],
    toolbar: { buttons: ["close"] },
    labels: {},
    animation: {
        fade: 330,
        swipe: 500,
    },
    carousel: {
        finite: false,
        preload: 2,
        padding: "16px",
        spacing: "30%",
    },
    controller: {
        focus: true,
    },
    on: {},
};
