import { createContext, useState, useEffect } from "react";
import { SectionRef } from "../../interfaces/sectionRef";
import useHeightFromTop from "../useHeightFromTop/useHeightFromTop";
export default function useCurrentSection(refs: SectionRef[]) {
  const [currentSection, SetCurrentSection] = useState(refs[0]);
  const [headerVisible,SetHeaderVisible] = useState("header hidden-header");
  useEffect(() => {
    const handleHeaderNameChanges = () => {
      let windowHeight = window.scrollY;
      if (windowHeight < refs[0].ref.current.clientHeight - 20) {
        SetHeaderVisible("header hidden-header");
      } else {
        SetHeaderVisible("header header-active");
      }

      refs.forEach((section: SectionRef, index: any) => {
          if(index > 0 && index !== (refs.length - 1)) {
            if(windowHeight > refs[index].heightFromTop - 20 && windowHeight < refs[index + 1].heightFromTop - 20)
            {
              SetCurrentSection(section);
            }
          } 
          if(index === (refs.length - 1) && windowHeight > section.heightFromTop-20){
              SetCurrentSection(section);
          }
      });

      
    };
    handleHeaderNameChanges();
    window.addEventListener("scroll", handleHeaderNameChanges);
    return () => {
      window.removeEventListener("scroll", handleHeaderNameChanges);
    };
  }, []);
  return {currentSection, headerVisible};
}
