interface IProps {
    index: number;
    openImage: any;
    src: string;
  }
  
  export default function SvgImageExtraLarge(props: IProps) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="736px" height="988px" viewBox="0 0 736 988"
        onClick={() => props.openImage(props.index)}
      >
        <defs>
          <clipPath id="user-space-4" clipPathUnits="userSpaceOnUse">
          <path d="M10.3837355,-6.76735432e-15 L725.616264,4.19515344e-16 C729.226916,-2.43750496e-16 730.536225,0.375943743 731.856225,1.0818875 C733.176225,1.78783126 734.212169,2.82377503 734.918112,4.1437751 C735.624056,5.46377516 736,6.77308448 736,10.3837355 L736,977.616264 C736,981.226916 735.624056,982.536225 734.918112,983.856225 C734.212169,985.176225 733.176225,986.212169 731.856225,986.918112 C730.536225,987.624056 729.226916,988 725.616264,988 L10.3837355,988 C6.77308448,988 5.46377516,987.624056 4.1437751,986.918112 C2.82377503,986.212169 1.78783126,985.176225 1.0818875,983.856225 C0.375943743,982.536225 3.85077758e-15,981.226916 -6.62751587e-15,977.616264 L2.79676896e-16,10.3837355 C-1.62500331e-16,6.77308448 0.375943743,5.46377516 1.0818875,4.1437751 C1.78783126,2.82377503 2.82377503,1.78783126 4.1437751,1.0818875 C5.46377516,0.375943743 6.77308448,3.93202775e-15 10.3837355,-6.76735432e-15 Z" id="path-1"></path>
          </clipPath>
        </defs>
        <image
          width="100%"
          height="100%"
          preserveAspectRatio="xMinYMin slice"
          xlinkHref={props.src}
          clipPath="url(#user-space-4)"
        />
      </svg>
    );
  }
  