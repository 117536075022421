import { useEffect, useLayoutEffect, useState } from "react";
import Logo from "../../../assets/svg/logo";
import useCurrentSection from "../../../hooks/useCurrentSection/useCurrentSection";
import "./splash.scss";

export default function Splash(props: any) {
  const [active, setActive] = useState(false)
  const links = [
    "human",
    "designer",
    "photographer",
    "motorcyclist",
    // "speaker",
    // "writer",
  ];

  useLayoutEffect(() => {
    if(!active) {
      setTimeout(() => {
        setActive(true);

      }, 3000)
    }
  });

  
  return (
    <section className="splash " ref={props.refProp}>
      <div className="section-body-splash-logo-holder">
        <Logo white={"light"} />
      </div>
      <div className={"section-body-splash"}>
       
        <div className="section-body-splash-text-holder">
          <div className="name">
            Sebastiaan
            <br /> de With
          </div>
          <div className="tag-holder">
            {links.map((link, i) => (
              <a
                className={active ? "link active" : "link"}
                key={i}
                onClick={(e) => props.navButtonHandler(link)}
              >
                {link}
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
