import "./photo.scss";
import { useState } from "react";
import Lightbox from "../../lightiebox";
import photos from "./data.json";
import { useInView } from "react-intersection-observer";
import SvgImageSmall from "../../svgImage/svgImageSmall";
import SvgImageMedium from "../../svgImage/svgImageMedium";
import SvgImageExtraLarge from "../../svgImage/SvgImageExtraLarge";
import SvgImageLarge from "../../svgImage/SvgImageLarge";

export default function Photo(props: any) {
  const [index, setIndex] = useState(-1);
  const images = photos;
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.09,
  });

  const openImage = (number: number) => {
    setIndex(number);
  };

  return (
    <section className={"photo"} ref={props.refProp} id="photographer">
      <div
        className={
          inView ? "section-body-photo is-intersecting" : "section-body-photo "
        }
        ref={ref}
      >
        <div className="photo-grid-line-holder">
          <div className="photo-grid-line line-1">
            <div className="picture-large">
            <SvgImageLarge index={0} src={photos[0].src} openImage={openImage} />
            </div>
            <div className="pictures-container">
              <div className="pictures-container-holder columflex">
                <div className="pictures-container-holder-line">
                  <div className="picture-small">
                  <SvgImageSmall index={1} src={photos[1].src} openImage={openImage} />
                  </div>
                  <div className="picture-small">
                  <SvgImageSmall index={2} src={photos[2].src} openImage={openImage} />
                  </div>
                </div>
                <div className="pictures-container-holder-line">
                  <div className="picture-small">
                  <SvgImageSmall index={3} src={photos[3].src} openImage={openImage} />
                  </div>
                  <div className="picture-small">
                    <SvgImageSmall index={4} src={photos[4].src} openImage={openImage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="photo-grid-line line-2">
            <div className="pictures-container medium-picture-width">
              <div className="pictures-container-holder columflex  ">
                <div className="picture-medium-large   ">
                <SvgImageMedium index={5} src={photos[5].src} openImage={openImage} />
                  
                </div>
                <div className="picture-medium-large  ">
                  <SvgImageMedium index={6} src={photos[6].src} openImage={openImage} />
                </div>
              </div>
            </div>
            <div className="pictures-container xl-picture-width">
            <SvgImageExtraLarge index={7} src={photos[7].src} openImage={openImage} />
            </div>
          </div>
         <div className="photo-grid-line line-3">
            <div className="picture-small-inline">
            <SvgImageSmall index={8} src={photos[8].src} openImage={openImage} />
            </div>
            <div className="picture-small-inline">
            <SvgImageSmall index={9} src={photos[9].src} openImage={openImage} />
              
            </div>
            <div className="picture-small-inline">
            <SvgImageSmall index={10} src={photos[10].src} openImage={openImage} />
            </div>
            <div className="picture-small-inline">
            <SvgImageSmall index={11} src={photos[11].src} openImage={openImage} />
              
            </div>
          </div>
          <div className="photo-grid-line text-line">
            <div className="photo-grid-line-text">
              See more of my photography on{" "}
              <a href="https://instagram.com/sdw">Instagram.</a>
            </div>
          </div>
        </div>
      </div>

      <Lightbox
        slides={images}
        open={index >= 0}
        index={index}
        labels={{ Next: "Next slide" }}
        close={() => setIndex(-1)}
      />
    </section>
  );
}
