import { useEffect, useLayoutEffect, useState } from "react";
import Logo from "../../assets/svg/logo";
import useCurrentSection from "../../hooks/useCurrentSection/useCurrentSection";
import useHeightFromTop from "../../hooks/useHeightFromTop/useHeightFromTop";
import { SectionRef } from "../../interfaces/sectionRef";
import "./overlay.scss";

export default function Overlay(props: any) {
  const { currentSection, headerVisible } = useCurrentSection(props.refs);
  const refs = useHeightFromTop(props.refs);

  const linkClick = (link: string) => {
    const index = refs.findIndex((x: SectionRef) => x.sectionName === link);
    window.scrollTo({
      top: refs[index].heightFromTop,
      behavior: "smooth",
    });
    props.hideOverlay();
  };

  return (
    <div className="overlay" id="fullscreen_nav">
      <div className={"section-body-header"}>
        <div
          className="name"
          onClick={(e) => {
            props.hideOverlay();
            e.stopPropagation();
          }}
        >
          Sebastiaan
          <br /> de With{" "}
        </div>
        <div className="section-body-header-logo-holder">
          <Logo white={"light"} />
        </div>
        <div
          className="tag-holder"
          onMouseLeave={(e) => {
            props.hideOverlay();
            e.stopPropagation();
          }}
        >
          <div className={"active link"}>{currentSection.sectionName}</div>

          {props.refs.map((sectionRef: SectionRef, i: number) => {
            if (sectionRef.sectionName == currentSection.sectionName || i < 1) {
              return;
            }
            return (
              <a
                className={"link"}
                key={i}
                onClick={(e) => linkClick(sectionRef.sectionName)}
              >
                {sectionRef.sectionName}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
