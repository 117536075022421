import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/svg/logo";
import useCurrentSection from "../../hooks/useCurrentSection/useCurrentSection";
import useScrollPosition from "../../hooks/useScrollPosition/useScrollPosition";
import "./header.scss";

interface IProps {
  name: any;
  openOverlay: any;
  refProp: any;
  refs: any[];
}

export default function Header(props: IProps) {
  const { currentSection, headerVisible } = useCurrentSection(props.refs);
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        console.log(id)
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change



  

  return (
    <section className={headerVisible} ref={props.refProp}>
      <div className={`header-blurr`}></div>
      <div
        className={
          currentSection.blurr
            ? `header-background light `
            : `header-background light visible`
        }
      ></div>
      <div
        className={
          currentSection.blurr
            ? `header-background dark  visible`
            : `header-background dark`
        }
      ></div>

      <div className={"section-body-header"}>
        <div className={`name small ${currentSection.className}`}>sdw</div>
        <div className="section-body-header-logo-holder">
          <Logo white={currentSection.className} />
        </div>
        <div className={`tag small ${currentSection.className}`}>
          <span
            onMouseOver={
              headerVisible !== "header hidden-header"
                ? props.openOverlay
                : null
            }
          >
            {currentSection.sectionName}
          </span>
        </div>
      </div>
    </section>
  );
}
